import React from "react";
import { Link } from "gatsby";
// import Img from "gatsby-image";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import tppm from "../../images/taylor-parks-plat-map.jpg";
import "../../css/plans.css";

class TaylorParksPage extends React.Component {
  render(){

    return (
      <Layout>
        <SEO title="Plans" />
        <div className="container">

          <div className="row">
            <div className="col-sm mt-auto">
              <h1 className="mt-4">Taylor Parks Subdivision</h1>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm">

              <p>Nestled perfectly in Plain City, Taylor Parks is the perfect subdivision with a country feel.</p>

              <div className="row mt-4 mb-4">
                <div className="col-sm-6">
                  <div>
                    <img src={tppm} className="img-fluid" alt="alt"/>
                  </div>
                  <Link to="contact-us"><button type="button" className="btn btn-success btn-lg mt-3">REQUEST MORE INFO</button></Link>
                </div>
                <div className="col-sm-6">
                  <iframe title="Taylor Parks Map" border="0" width="100%" height="500" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2160.8893128744817!2d-112.07889305090909!3d41.30187694904388!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x875312c60125bb17%3A0x0!2zNDHCsDE4JzA3LjAiTiAxMTLCsDA0JzM4LjAiVw!5e1!3m2!1sen!2sus!4v1518204187123" frameborder="0" allowfullscreen=""></iframe>
                </div>
              </div>
            </div>

          </div>

        </div>

      </Layout>
    )
  }
};

export default TaylorParksPage;
